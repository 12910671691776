// Hero Header
.form_header{
    .sd_header_top_title {
        max-width: 1680px;
        width: 100%;
        padding: 40px 6% 60px;
        // padding: 60px 15px;
        // @include mediaQuery($){
        //     padding: 0px 15px 40px;
        //  }
        @include mediaQuery($mobile){
           padding: 100px 15px 40px;
        }
        h1{
            @include defaultFontStyleFpg($font_family_bold,60,58,$white,-1.2px);
            @include mediaQuery($ipad){
                @include mediaFontStyle(40, 45)
            }
            @include mediaQuery($mobile){
                @include mediaFontStyle(30, 35)
            }
        }
    }
}