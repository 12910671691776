// Colors
$black: #000000;
$white: #FFFFFF;
$purple: #7758B3;
$darkGreen: #146E96;
$lightGreen: #65FADC;
$yellow: #FFB30F;
$yellowish: #FED105;
$yellowcircle: #FFC52B;
$pink: #FF625A;
$lightGrey: #C2C2C2;
$lighterGrey: #C9C9C9;
$darkGrey: #9A9A9A;
$switchBG: #F1F1F1;
$red: #E74C3C;
$lightRed: #FE766F;
$whisper: #ECECEC;
$trinidad: #D22846;
$resetcolor:#FF4841;
$nobel: #A2A2A2;
$gainsboro: #E1E0E0;
$whiteSmoke: #F6F5F5;
$cream: #FFFFCC;
$lynxWhite:#E5E5E5;
$lightYellow:#FFCC09;
$lynxWhite2:#f7f7f7;
$lygreen:#04AC80;
$lightgn:#04ac80;
$lylightgreen:#00B2C0;
$link_green:#0ffffb;
$placeholder:#7B7B7B;
$formborder:#D8D8D8;
$font_family_normal:'ABCMonumentGrotesk_Regular';
$font_family_bold:'ABCMonumentGrotesk_Bold';
$font_family_medium:'ABCMonumentGrotesk_medium';
$font_family_MMnormal:'MonumentGrotesk_Regular';
$font_family_MMmedium:'MonumentGrotesk_Medium';
$font_family_MMbold:'MonumentGrotesk_Bold';
$font_family_light:'Pangea_light';
// Breakpoints for Media Query
$smallMobile: 325px;
$mobileSmall: 375px;
$mobile: 479px;
$mobileLarge:576px;
$ipad: 768px;
$large: 991px;
$extraLarge: 1024px;
$desktop: 1366px;
$smalldesk: 1080px;
