/* Footer */ 
footer {
    background: $black; 
    position: relative;
    margin-top: 90px;
    @include mediaQuery($ipad){
        margin-top: 0;
        float: left;
        display: block;
        width: 100%;
    }
    .sd_large_footer{
        @include mediaQuery($ipad){
            float: left;
            display: block;
            width: 100%;
        }
        .sd_footer_links_wpr{
            @include mediaQuery($ipad){
                float: left;
                display: block;
                width: 100%;
                background-color: $black;
            }
            .sd_large_container{
                @include mediaQuery($ipad){
                    float: left;
                    display: block;
                    width: 100%;
                }
            }
            &:first-child{
                position: relative;
                &::after{   
                    content: '';
                    position: absolute;
                    bottom: 0%;
                    left: 0;
                    width: 100%;
                    height: 2px;
                    background-color: $white;
                    @include mediaQuery($ipad){
                        display: none;
                    }
                }
                @include mediaQuery($ipad){
                    padding-left: 0;
                }
                .sd_large_container{
                    max-width: 1680px;
                    margin: 0 auto;
                    padding: 48px 60px 0 40px;
                    width: 100%;
                    @include mediaQuery($mobileLarge)
                    {
                        padding: 30px 48px 10px 48px;
                    }
                    &.sd_footer_container{
                        max-width: 1440px;
                        width: 100%;
                        padding: 60px 0 25px;
                        margin: 0 auto;
                        @include mediaQuery($mobileLarge)
                        {
                            padding: 36px 15px 32px 15px;
                        }
                    }
                }
                .sd_footer_links_content{ 
                    &.sd_mobile_footer{
                        display: none;
                        @include mediaQuery($mobileLarge){
                            display: block;
                            float: left;
                            width: 100%;
                        }
                        & > div{
                            float: left;
                            width: 50%;
                            display: block;
                        }
                    }
                    &.sd_desktop_footer{
                        @include mediaQuery($mobileLarge){
                            display: none;
                        }
                    }
                    @include mediaQuery($large){
                        flex-wrap: wrap;
                        justify-content: flex-start;
                    }
                    @include mediaQuery($ipad){
                        float: left;
                        display: block;
                        width: 100%;
                    }
                    .sd_footer_links{
                        width: 33.33%;
                        @include mediaQuery($ipad){
                            float: left;
                            padding-bottom: 5px;
                            width: 50%;
                        }
                        @include mediaQuery($mobile){
                            width: 100%;
                        }
                        label{
                            @include defaultFontStyleFpg($font_family_bold, 19, 20, $white);
                            font-weight: bold;
                        }
                        ul{
                            margin-top: 20px;
                            @include mediaQuery($mobileLarge){
                                margin-bottom: 15px;
                                margin-top: 15px;
                            }
                            li{
                                margin-bottom: 15px !important;
                                @include mediaQuery($mobileLarge){
                                    margin-bottom: 5px !important;
                                }
                                span{
                                    @include defaultFontStyleFpg($font_family_normal, 15, 19, $white);
                                    cursor: pointer;
                                    @include mediaQuery($mobileLarge){
                                        @include mediaFontStyle(13,19)
                                     }
                                    &:hover{
                                        color: $link_green;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &:nth-child(2){
                // .sd_large_container{
                //     max-width: 1680px;
                //     margin: 0 auto;
                //     padding: 0 0 0 20px;
                //     width: 100%;
                //     @include mediaQuery($extraLarge){
                //         padding: 0 !important;
                //     }
                //     @include mediaQuery($ipad){
                //         padding-left: 0;
                //     }
                    .sd_footer_wpr{   
                        margin-left: 02rem;
                        @include mediaQuery(1710px){
                            overflow-x: hidden;
                        }
                        @include mediaQuery($extraLarge){
                            @include flex_dir_column;
                            padding: 20px 0px;
                            margin-left: 0;
                        }
                        @include mediaQuery($ipad){
                            align-items: flex-start;
                        }
                        @include mediaQuery($mobile){
                            padding: 0;
                            .sd_flex_1_auto{
                                width: 100%;
                                padding:0 20px 0 0;
                            }
                    > li{
                        border: none;
                        width: auto;
                        // margin-right: 29.17px;
                        margin-right: 16px;
                        @include flex_shrink;
                        &:last-child{
                            margin-right: 0;
                        }
                        @include mediaQuery($mobile){
                            margin-right: 10px ;
                        }
                        @include mediaQuery($mobileSmall){
                            // margin-right: 22px;
                            margin-right: 5px;
                        }
                    }
                }
                .sd_flex_1_auto {
                    @include mediaQuery($extraLarge){
                        width: 100%;
                        padding: 0 10px;
                    }
                    @include mediaQuery($ipad){
                        margin: 36px 0 30px 0;
                    }
                }
                    }
            .sd_flex_1_auto{
                @include mediaQuery($ipad){
                    order: 1;
                }
                @include mediaQuery($mobileLarge){
                    // display: block;
                }
            }
            .sd_footer_logo{    
                max-width: 514px;
                width: 100%;
                padding-right: 10px;
                padding: 8px 10px 25px 0;
                @include flex_dir_column;
                @include mediaQuery($desktop){
                    padding: 0px 10px 15px 0;
                    max-width: 332px;
                }
                @include mediaQuery($extraLarge){
                    max-width: 200px;
                }
                @include mediaQuery($large){
                    padding-left: 20px;
                }
                @include mediaQuery($large){
                    padding-left: 20px;
                }
                @include mediaQuery($ipad){
                    // max-width: 295px;
                    max-width: 154px;
                    padding-left: 15px;
                    min-height: 116px;
                    margin-left: 10px;
                }
                // @include mediaQuery($mobile){
                //     max-width: 110px;
                // }
                @include mediaQuery($ipad){
                    padding: 0 ;
                }

                .sd_footer_festival_logo{
                    margin-right: 26px;
                    @include mediaQuery($mobile){
                        height: 66px;
                        img{
                            height: 100%;
                        }
                    }
                }
                .sd_footer_main_logo{
                    @include mediaQuery($mobileLarge){
                        justify-content: space-between;
                    }
                    img{
                        max-width: 100px;
                        width: 100%;
                        @include mediaQuery($ipad){
                            max-width: 154px;
                        }
                    }
                    .footer_webby_logo{
                        width: 104px;
                        margin-left: 108px;
                        @include mediaQuery($ipad){
                            margin-left: 45px;
                        }
                    }
                }
                p{
                    @include defaultFontStyleFpg($font_family_medium, 14, 15, $white,0.32px);
                    text-transform: uppercase;
                    padding-top: 28px;
                    @include mediaQuery($extraLarge){
                        display: none;
                    }
                }
            }

            .sd_footer_navbar{	
                // @include flex_shrink;	
                
                ul{	
                    list-style: none;	
                    margin-right: 33px; 	
                    @include mediaQuery($desktop){	
                        margin-right: 20px; 
                    }	
                    @include mediaQuery($extraLarge){	
                        // @include flex_center_center;	
                        // @include flex_wrap;	
                        margin: 0 20px 20px; 	
                    }	
                    
                    @include mediaQuery($large){	
                        @include f_wrap;
                        li{
                            width: 100%;
                        }
                    }	
                    @include mediaQuery($mobile){	
                        display: block;	
                        margin: 20px 0;
                    }	
                }	
                li{	
                    text-align: right;	
                    padding: 17px 0;	
                    @include mediaQuery($extraLarge){	
                        padding: 8px 20px;	
                    }	
                    @include mediaQuery($mobile){	
                        text-align: right;	
                        padding: 8px 0px;
                    }	
                    a{  
                        @include defaultFontStyleFpg($font_family_bold, 13, 17, $white);  
                        text-transform: capitalize;
                        &:hover{    
                            color: $link_green; 
                        } 
                        @include mediaQuery($ipad){   
                            @include defaultFontStyleFpg($font_family_bold, 13, 17, $white);
                        }       
                    }   
                }	
            }

            .sd_footer_navigation_bar{
                // position: absolute;
                bottom: 0;
                right: 0;
                @include flex_align_center;
                width: 38%;
                @include mediaQuery($extraLarge){
                    position: static;
                    @include flex_dir_column;
                    width: 100%;
                }
                @include mediaQuery(574px){
                    padding-right: 0;
                }
            }
            .sd_footer_nav{
                // max-width: 414px;
                width: 100%;
                @include flex_shrink;
                
                @include mediaQuery($extraLarge){
                    border-top: 2px solid $white;
                    border-bottom: 2px solid $white;
                }
                @include mediaQuery($mobile){
                    max-width: 100%;
                    width: 100%;
                }
                & > ul {
                    &:nth-child(1){
                        border-bottom: 2px solid $white;
                    }
                }
                ul{
                    border-left: 2px solid $white;
                    @include mediaQuery($extraLarge){
                        border-left: 0;
                    }
                    @include mediaQuery($ipad){
                        margin: 0 auto;
                    }
                    @include mediaQuery($mobile){
                        border: 0;
                        max-width: 100%;
                        width: 100%;
                        padding: 0;
                    }
                    @include mediaQuery(374px){
                        // @include flex_dir_column;
                    }
                    &:nth-child(2) {
                        li {
                            border-bottom: 0;
                            color: $white !important;
                            // max-width: 100% !important;
                        }
                    }

                    li{
                        border-right: 2px solid $white;
                        width: 50%;
                        list-style: none;
                        @include mediaQuery(374px){
                            // border: 0;
                        }

                        ul{
                            border: none;
                            // padding: 0 23px 0 33px;
                            justify-content: center;
                            flex-wrap: wrap;
                            @include mediaQuery($mobileLarge){
                                flex-wrap: nowrap;
                            }
                            @include mediaQuery($mobile){
                                @include justify_center;
                            }
                            
                            @include mediaQuery(374px){
                                @include justify_center;
                                @include flex_dir_row;
                            }
                            > li{
                                border: none;
                                @include flex_shrink;
                                width: calc(32px + 0.5em * 2);
                                margin-right: 5px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                @include mediaQuery(1605px){
                                    width: calc(28px + 0.5em * 2);
                                    margin-right: 0;
                                }
                                &:last-child{
                                    margin-right: 0;
                                }
                                @include mediaQuery($mobileLarge){
                                    margin-right: 10px ;
                                    width: auto;
                                }
                                @include mediaQuery($mobileSmall){
                                    // margin-right: 22px;
                                    margin-right: 5px;
                                }
                                &.sff_tiktok_icon{
                                    a{
                                        img{
                                            width: 21px;
                                        }
                                    }
                                }
                            }
                            a{
                                padding: 0;
                                height: calc(32px + 0.5em * 2);
                                @include mediaQuery(1605px){
                                    height: calc(28px + 0.5em * 2);
                                }
                                i{
                                    font-size: 21px;
                                    @include mediaQuery(1605px){
                                        font-size: 19px;
                                    }
                                }
                                img{
                                    width: 21px;
                                    @include mediaQuery(1605px){
                                        width: 19px;
                                    }
                                }
                            }
                        }
                        &:last-child{
                            border-right: 0;
                        }
                    }            
                }
                a{
                    @include defaultFontStyleFpg($font_family_bold, 13, 17, $white);
                    padding: 10px;
                    text-align: center;
                    height: 107px;    
                    @include flex_center_center;
                    @include transitionStyle(0.2, linear);
                    &.sd_collab_logo{
                        margin: auto;
                        @include mediaQuery($mobileLarge){
                            max-width: 100%;
                        }
                    }
                    &:hover{
                        color: $link_green;
                    }
                    @include mediaQuery($mobileLarge){
                        @include defaultFontStyleFpg($font_family_normal, 11, 15, $white);
                    }
                    @include mediaQuery($mobile){
                        height: 95px;   
                    }
                    @include mediaQuery(374px){
                        @include flex_dir_column;
                    }
                }
            }

            .sd_footer_copyright{
                @include mediaQuery($extraLarge){
                    display: block;
                    padding: 25px 18px;
                    margin-bottom: 3px;  
                    @include mediaQuery($ipad){
                        order: 3;
                        padding: 0 10px;
                        margin-bottom: 40px;
                    }
                    @include mediaQuery($mobileLarge){
                        margin-bottom: 60px;
                    }

                }
                p{
                    @include defaultFontStyleFpg($font_family_normal, 12, 12, $white);
                    @include mediaQuery($ipad){
                        @include mediaFontStyle(12,15);
                        letter-spacing: 0.32px;
                        text-transform: uppercase;
                        max-width: 217px;
                        width: 100%;
                    }
                }
            }
        // }
    }
}
}
}