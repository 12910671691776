// Question Help Tooltip
.sd_question_button{
    position: relative;
    @include buttonReset;
    &:hover{
        span{
            display: block;
        } 
    }
    &.tooltip-button{
        .__react_component_tooltip{
            // box-shadow: 2px 3px 4px 0 rgba($black, 0.3);
            ul{
                list-style: inside;
            }
        }
    }
    &.tooltip-button-white{
        img{
            filter: brightness(1.5);
        }
        .__react_component_tooltip{
            background: $white !important;
            // box-shadow: 2px 3px 4px 0 rgba($black, 0.3);
            margin-top: 55px;
            border-radius: 0;
            &.place-left{
                margin-left: -15px;
                &:after{       
                    top: -7px;
                }
            }
            &.place-right{
                margin-left: 25px;
            }
            &.place-top{
                margin-top: -5px;  
                margin-left: 30px;
                &:before{
                    bottom: -20px;                    
                    top: auto;    
                    left: 50% !important;
                }
            }
            &.place-bottom{
                margin-top: 30px;  
                margin-left: -30px;
                &:before{                
                    top: -20px;    
                    left: 50% !important;
                }
            }
            &:before, &:after{
                filter: brightness(1.5);
                background: url('../images/tooltip_arrow_white.svg') !important;
                width: 23px !important;
                height: 21px !important;
                border: none;
                margin-top: 0;
                top: 3px;
            }
            &:before{
                left: -24px !important;
            }
            &:after{
                right: -20px;
            }
        }
    }
    span{
        display: none;
        position: absolute;
        width: 333px;
        background: #E1E0E0;
        @include defaultFontStyle(400, 12, 16, $black);        
        top: 50%;
        transform: translateY(-50%);
        left: 24px;
        padding: 14px 10px 6px;
        text-align: left;
        z-index: 6;
        @include mediaQuery($mobile) {
            @include mediaFontStyle(10, 15); 
            width: 180px;
            padding: 10px;
        }

        &::before{
            @include content;
            background: url('../images/tooltip_arrow_icon.svg');
            left: -13px;
            z-index: 5;
            width: 13px;
            height: 53px;
            bottom: 26px;
        }
        &:after{
            @include content;
            left: 0;
            top: -25px;
            height: 100%;
            width: 30px;
        }
    }
}

.sd_tooltip_button{
    position: relative;
    padding: 5px 0 !important;
    .sd_button_tooltip{
        display: none;
        position: absolute;
        background: $white;
        border-radius: 5px;
        box-shadow: 0 2px 4px rgba($black, 0.5);
        width: 128px;
        @include defaultFontStyle(400, 11, 11, $black, -0.26px);
        padding: 5px 7px;    
        text-align: center; 
        left: -6px;
        top: -13px;
        &::before{
            @include content;
            background: $white;
            @include content;
            @include square(10px);
            background: $white;
            border-right: 0;
            border-top: 0;
            top: auto;
            bottom: -9px;
            left: 9px;
            -webkit-transform: translateY(-50%) rotate(-45deg);
                -ms-transform: translateY(-50%) rotate(-45deg);
                    transform: translateY(-50%) rotate(-45deg);
            z-index: 5;

        }
    }
}


body{

    .__react_component_tooltip{

        width: 333px;
        background: $gainsboro !important;
        @include defaultFontStyle(400, 12, 16, $black);   
        padding: 12px 10px 8px !important;
        text-align: left; 
        border-radius: 0;
        // opacity: 1;
        // visibility: visible;

        @include mediaQuery($mobile) {
            @include mediaFontStyle(10, 15); 
            width: 180px;
            padding: 10px;
        }

        &.show {
            opacity: 1;
        }

        &.place-top {
            margin-top: 0;
            &:before{
                transform: translateX(-50%) rotate(-90deg);
                border: none !important;
                bottom: -30px;
                margin-left: -20px;
            }
            &:after{
                display: none;
            }
        }

        &.place-right{
            &::before{
                left: -11px !important;
                top: 17px;
            }
            &:after{
                display: none;
            }
        }

        &.place-bottom {
            margin-top: 45px;
            &:before{
                transform: translateX(-50%) rotate(90deg);
                border: none !important;
                top: -30px;
                margin-left: 20px;
            }
            &:after{
                display: none;
            }
        }

        &.place-left{
            margin-left: -5px;
            &::before{
                display: none;
            }
            &:after{
                transform: rotateY(135deg);
                top: 17px;
                right: -10px;
            }
        }

        &::before, &::after{
            background: url('../images/tooltip_arrow_icon.svg') no-repeat !important;
            width: 11px !important;
            height: 48px !important;
            border: none !important;
        }

        a{
            @include defaultFontStyle(400, 12, 16, $black);  
            text-decoration: underline;
            &.mobile{
                text-decoration: none;
            }
        }
    }

}
// Live events tooltip
.tooltip-events-accessibility{
    width: 290px;
    .__react_component_tooltip{
        &.place-right{
            margin-left: 15px;
        }
        &.place-bottom{
            margin-top: 20px;
        }
    }
}
// New frontier
.tooltip-events-frontier{
    .__react_component_tooltip{
        width: 290px;
        @include mediaQuery($mobileSmall){
            width: 200px;
        }
        &.place-bottom{
            margin-top: 20px;
            margin-left: 10px;
        }
        &.place-left{
            margin-left: 10px;
            @include mediaQuery($mobile){
                margin-left: 0;
            }
            @include mediaQuery($mobileSmall){
                margin-top: 20px;
            }
        }
    }    
}

// Short program
.tooltip-short-program{
    .__react_component_tooltip{
        &.place-right{
            &::before {
                left: -10px !important;
                top: 10px;
            }
        }
        &.place-top{
            margin-top: 20px;
        }
        &.place-left{
            margin-left: -10px;
            &:after{
                transform: rotateY(180deg);
                top: 7px;
                right: -10px;
            }
        }
    }
}

.tooltip-schedule-header{
    .__react_component_tooltip{
        &.place-right{
            margin-left: 5px;
            &:before{
                left: -9px !important;
                top: 20px;
            }
        }
    }
}


.tooltip-schedule-time{
    margin-left: 10px;
    .__react_component_tooltip{
        width: 220px;
        &.place-top {
            margin-top: -10px;
            margin-left: 0 !important;
        }
        &.place-bottom {
            margin-top: 20px;
            margin-left: 0 !important;
        }
    }
}
.popup-content{
    padding: 10px;
    max-width: 250px;
    background: $black !important;
    margin: auto;
    border: unset !important;
    // > div{
    //     &:not([class]){
            
    //     }
    // }
    .popup-arrow{
        color: $black;
    }
    span,p{
        @include defaultFontStyleFpg($font_family_normal, 12, 16, $white); 
    }
}