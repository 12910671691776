// // Fonts

// @font-face {
// 	font-family: "Pangea";
// 	src:url('../fonts/Pangea-Regular.eot'); 
// 	src:url('../fonts/Pangea-Regular.ttf') format('truetype'),url('../fonts/Pangea-Regular.woff') format('woff'),url('../fonts/Pangea-Regular.svg') format('svg');
//     font-style: normal;
// 	font-display: swap;
// }
// @font-face {
//     font-family: "Pangea_bold";
// 	src: url('../fonts/Pangea-Bold.eot');
// 	src: local('☺'), url('../fonts/Pangea-Bold.ttf') format('truetype'),url('../fonts/Pangea-Bold.woff') format('woff'),  url('../fonts/Pangea-Bold.svg') format('svg');
// 	font-weight: normal;
// 	font-style: normal;
// }
// @font-face {
// 		font-family: "Pangea_medium";
// 		src: url('../fonts/Pangea-Medium.eot');
// 		src: local('☺'),url('../fonts/Pangea-Medium.ttf') format('truetype'), url('../fonts/Pangea-Medium.woff') format('woff'),  url('../fonts/Pangea-Medium.svg') format('svg');
// 		font-weight: normal;
// 		font-style: normal;
// }
// @font-face {
// 	font-family:  "Pangea_light";
// 	src: url('../fonts/Pangea-Light.eot');
// 	src: local('☺'),url('../fonts/Pangea-Light.ttf') format('truetype'), url('../fonts/Pangea-Light.woff') format('woff'),  url('../fonts/Pangea-Light.svg') format('svg');
// 	font-weight: normal;
// 	font-style: normal;
// }

@font-face {
	font-family: "ABCMonumentGrotesk_Regular";
	  src: url('https://d2wsrejhnxatgp.cloudfront.net/assest/ABCMonumentGrotesk-Regular.woff2') format('woff2'),url('https://d2wsrejhnxatgp.cloudfront.net/assest/ABCMonumentGrotesk-Regular.otf') format('otf');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
  }
  @font-face {
	font-family: "ABCMonumentGrotesk_medium";
	src: url('https://d2wsrejhnxatgp.cloudfront.net/assest/ABCMonumentGroteskMono-Medium.woff2') format('woff2'),url('https://d2wsrejhnxatgp.cloudfront.net/assest/ABCMonumentGroteskMono-Medium.otf') format('otf');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
  }
  @font-face {
	font-family: "ABCMonumentGrotesk_Bold";
	src: url('https://d2wsrejhnxatgp.cloudfront.net/assest/ABCMonumentGrotesk-Bold.woff2') format('woff2'),url('https://d2wsrejhnxatgp.cloudfront.net/assest/ABCMonumentGrotesk-Bold.otf') format('otf'), url('https://d2wsrejhnxatgp.cloudfront.net/assest/ABCMonumentGrotesk-Bold.ttf') format('ttf');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
  }
  @font-face {
	font-family: "MonumentGrotesk_Regular";
	src:url('https://d2wsrejhnxatgp.cloudfront.net/assest/ABCMonumentGrotesk-Regular.woff2') format('woff2'),;
    font-style: normal;
	font-display: swap;
  }
  @font-face {
	font-family: "MonumentGrotesk_Medium";
	src:url('https://d2wsrejhnxatgp.cloudfront.net/assest/ABCMonumentGroteskMono-Medium.woff2') format('woff2'),;
	font-style: normal;
	font-display: swap;
  }
  @font-face {
    font-family: "MonumentGrotesk_Bold";
	src:  url('https://d2wsrejhnxatgp.cloudfront.net/assest/ABCMonumentGrotesk-Bold.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
  }
h1 {
	@include defaultFontStyleFpg($font_family_normal, 80, 75, $black);
	font-weight: normal;
	@include mediaQuery($large) {
		@include mediaFontStyle(40, 60);
	}
	@include mediaQuery($mobile) {
		@include mediaFontStyle(30, 40);
	}
}
h2 {
	@include defaultFontStyleFpg($font_family_medium, 50, 55, $black,-0.96px);
	font-weight: normal;
	@include mediaQuery($large) {
		@include mediaFontStyle(30, 40);
	}
	@include mediaQuery($mobile) {
		@include mediaFontStyle(20, 30);
	}
}
h3 {
	@include defaultFontStyleFpg($font_family_normal, 40, 45, $black,-0.96px);
	font-weight: normal;
	letter-spacing: 0px;
	@include mediaQuery($ipad) {
		@include mediaFontStyle(30, 34);
	}
	@include mediaQuery($mobile) {
		@include mediaFontStyle(24, 26);
	}
}
h4 {
	@include defaultFontStyleFpg($font_family_medium, 22,23, $black,-0.53px);
	@include mediaQuery($ipad) {
		@include mediaFontStyle(18, 20);
	}
}
h5{
    @include defaultFontStyleFpg($font_family_medium,22,23,$black, -0.53px);
    @include mediaQuery($mobile){
        @include mediaFontStyle(18, 22);
    }
}
p {
	@include defaultFontStyleFpg($font_family_normal, 16, 23, $black);
	letter-spacing: -0.38px;
	@include mediaQuery($smallMobile) {
		@include mediaFontStyle(14, 20);
	}
}
span,a,label{
	@include defaultFontStyleFpg($font_family_normal, 14, 20, $black,-0.34px);
}