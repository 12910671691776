article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary { display: block; }
body, ol, ul, li, div, p, span, form, h1, h2, h3, h4, h5, h6, table, thead, th, tbody, tr, td, img, input, textarea, dd, dt, dl { word-wrap: break-word; outline: none; padding: 0; margin: 0; -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
textarea { overflow: auto; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }
h1, h2, h3, h4, h5, h6, p, ul, ol, dd, dt, dl { -webkit-margin-before: 0px; -webkit-margin-after: 0px; -webkit-margin-start: 0px; -webkit-margin-end: 0px; }
a img, img { border: none; outline: none; display: block; width: auto; max-width: 100%; }
a { cursor: pointer; text-decoration: none; outline: 0; }
input { -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; outline: none; }
html { font-size: 62.5%; } 
body { -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; -ms-font-smoothing: antialiased; background-color: $white; font-size: 62.5%; overflow-x: hidden; }
.no-scroll{ overflow: hidden !important; height: 100%;}
ul, menu, dir { margin: 0px; padding: 0px; display: block; list-style-type: disc; -webkit-margin-before: 0; -webkit-margin-after: 0; -webkit-margin-start: 0; -webkit-margin-end: 0; -webkit-padding-start: 0; }
* { -webkit-box-sizing: border-box; box-sizing: border-box;
    &:after, &:before {  -webkit-box-sizing: border-box; box-sizing: border-box; }
}
.clear { clear: both; height: 0px; overflow: hidden; width: auto; display: block; float: none !important; }
.no-script-msg { font: 12px Arial, verdana, Helvetica, sans-serif; background: $cream; width: auto; padding: 10px 10px 10px 25px; margin: 8px; border: 1px solid $red; font-weight: bold; height: auto; font-size: 11px; color: $black; line-height: 150%; clear: both; }
li:last-child, p:last-child { margin-bottom: 0 !important; }
img { -o-object-fit: cover !important; object-fit: cover !important;}
.required{ &::after{ content:"*";font-size: 18px;color: $black;} }

select{ outline: none; cursor: pointer; }
.mt_10{margin-top: 10px;}
.mb_0{margin-bottom: 0 !important;}
.pb_0{padding-bottom: 0 !important;}
.pt_0{padding-top: 0 !important;}
.border_bottom_2{border-bottom: 2px solid $black;}
.border_bottom_gray{border-bottom: 1px solid $gainsboro;}
.pt_10{padding-top: 10px;}
.mb_0{margin-bottom: 0 !important;}
.mb_5{margin-bottom: 5px !important;}
.mb_10{margin-bottom: 10px !important;}
.mb_15{margin-bottom: 15px !important;}
.mr_10{margin-right: 10px !important;}
.mr_15{margin-right: 15px !important;}
.mb_25{margin-bottom: 25px !important;}
.mt_10{margin-top: 10px !important;}
.mt_15{margin-top: 15px !important;}
.mt_25{margin-top: 25px !important;}
.m_0{margin: 0 !important;}
.mr_5{margin-right: 5px !important;}
.ml_5{margin-left: 5px !important;}
.mr_10{margin-right: 10px !important;}
.ml_10{margin-left: 10px !important;}
.ml_15{margin-left: 15px !important;}
.ml_25{margin-left: 25px !important;}
.mr_25{margin-right: 25px !important;}


.ml_0{margin-left: 0 !important;}
.mt_0{margin-top: 0 !important;}
.mt_5{margin-top: 5px !important;}

.pl_15{padding-left: 15px !important;}
.pr_20{padding-right: 20px !important;}
.pb_0{padding-bottom: 0 !important;}
.pb_10{padding-bottom: 10px !important;}
.pl_25{padding-left: 25px !important;}
.pb_15{padding-bottom: 15px !important;}
.pb_25{padding-bottom: 25px !important;}
.pt_5{padding-top: 5px !important;}
.pt_10{padding-top: 10px !important;}
.pt_15{padding-top: 15px !important;}
.pt_25{padding-top: 25px !important;}
.p_0{padding: 0 !important;}
.pl_0{padding-left: 0 !important;}
.pr_0{padding-right: 0 !important;}
.p_5{padding: 5px !important;}
.error_single{ margin-top: -20px;display: block;margin-bottom: 10px; }
.sd_error_single{
    @include mediaQuery($ipad){
        margin-top: -10px;
    }
}
.sd_container{
    max-width: 1710px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
}
.sd_container_720{
    max-width: 750px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
}
.sd_inner_container{
    max-width: 1510px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
}
.sd_width_full{
    width: 100%;
    display: block;
}
.sd_sub_container{
    max-width: 990px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
}

.success_form_msg{
    padding: 5px 20px;
    border: 2px solid;
    max-width: 430px;
    margin: 0 auto;
    position: fixed;
    width: 98%;
    left: 0;
    right: 0;
    z-index: 3;
    top: 225px;
    transition: 0.5s ease-in-out;
    opacity: 0;
    visibility: hidden;
    background: $white;
    box-shadow: 0px 5px 5px 0px rgba(0 ,0 ,0 , 0.4);
    &.active{
        top: 245px;
        opacity: 1;
        visibility: visible;
    }
    p{
        text-align: center;
        @include defaultFontStyleFpg($font_family_medium,18,20,$lygreen);
    }
    .close_success{
        position: absolute;
        right: 10px;
        top: 5px;
    }
}
.failed_form_msg{
    padding: 5px 20px;
    border: 2px solid;
    max-width: 430px;
    margin: 0 auto;
    position: fixed;
    width: 98%;
    left: 0;
    right: 0;
    z-index: 3;
    top: 225px;
    transition: 0.5s ease-in-out;
    opacity: 0;
    visibility: hidden;
    background: $white;
    box-shadow: 0px 5px 5px 0px rgba(0 ,0 ,0 , 0.4);
    &.active{
        top: 245px;
        opacity: 1;
        visibility: visible;
    }
    p{
        text-align: center;
        @include defaultFontStyleFpg($font_family_medium,18,20,$red);
    }
    .close_success{
        position: absolute;
        right: 10px;
        top: 5px;
    }
}
.text_transform_unset {
    text-transform: unset !important;
}
.text_transform_capitalize {
    text-transform: capitalize !important;
}
.srv-validation-message{
    @include defaultFontStyleFpg($font_family_medium, 14, 26, $resetcolor);
    text-transform: uppercase;
}
.f_bold{
    font-weight: bold;
}
.share_check{
    input{
        & ~ label{
            pointer-events: none;
            &::before{
                background: $lynxWhite;
            }
        }  
    }
}