// 404 page 
.notfound{
    footer{
        margin-top: 0;
    }
    .sd_header_section{
        // background: #FF9000;
        position: relative;
        padding: 235px 0 290px 0px;
        // padding: 0 15px;
        z-index: 2;
        overflow: hidden;
        &::after
        {   
            content: '';
            height: 1548px;
            width: 1548px;
            // background-color:  #0084FF;
            position: absolute;
            left: 55%;
            top: -30%;
            border-radius: 50%;
            overflow: hidden;
            z-index: -1;
            // height: 1000px;
            // left: 68%;
            // top: -420px;
            // width: 1000px;
            @include mediaQuery($desktop){
                height: 1021px;
                width: 1076px;
                left: 70%;
                top: -126px;
            }
            // @include mediaQuery($large)
            // {
            //     left: 40%;
            //     top: -180px;
            // }
            @include mediaQuery($mobile){
                width: 554.15px;
                height: 851.01px;
                right: -70%;
                top: -68px;
            }
        }
    }
    .sd_not_found_header{
        max-width: 509px;
        width: 100%;
        @include mediaQuery($mobile)
        {
            max-width: 286px;
        }
        h2{
            @include defaultFontStyleFpg($font_family_bold, 48, 80, $black);
            // margin-bottom: 40px;
        }
        p{
            // @include defaultFontStyle(400, 40, 40, $white);
            @include defaultFontStyleFpg($font_family_bold, 28, 40, $black);
            font-size: 28px !important;
            line-height:40px !important;
            @include mediaQuery($ipad){
                @include mediaFontStyle(30, 40);
            }
            @include mediaQuery($mobile){
                @include mediaFontStyle(20, 30);
            }
        }
        .nofound_content{
            text-align: start;
            padding: 15px 0;
                a,span{
                    @include defaultFontStyleFpg($font_family_bold, 22, 40, $black, -0.53px);
                    color: $black !important;
                    @include mediaQuery($ipad){
                        @include mediaFontStyle(20, 30);
                    }
                } 
                .click_here{
                    text-decoration: underline;
                    margin-right: 8px;
                    cursor: pointer;
                }    
                img
                {
                    display: inline-block;
                }    
            
        }
    }
}

// Not found