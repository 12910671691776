// Banner component
.sd_banner{
    position: relative;
    z-index: 2;
    overflow: hidden;
    // background: $resetcolor;
    background-image:url("../images/sd_header_bg.svg") ;
    background-size: cover;
    &.sd_paf_banner{
        .sd_container{
            max-width: 1440px;
            width: 100%;
            margin: 0 auto;
            padding: 0 70px;
            @include mediaQuery($ipad){
                padding: 0 15px;
            }
            .sd_banner_inner{
                padding: 40px 0px 50px 0px;
            }
        }
    }
    &_inner{
        padding: 105px 20px 110px 99px;
        position: relative;
        @include mediaQuery(1280px){
            padding: 105px 20px 110px 10px;
        }
        @include mediaQuery(1280px){
            padding: 105px 30px 110px 30px;
        }
        @include mediaQuery($ipad){
            padding: 105px 30px 110px 10px;
        }
        @include mediaQuery(576px){
            @include f_wrap;
            padding: 50px 30px 10px;
        }
        &_title{
            // &::after{
            //     width: 746px;
            //     height: 746px;
            //     border-radius: 50%;
            //     position: absolute;
            //     content: "";
            //     left: -192px;
            //     top: -195px;
            //     background:$yellowcircle;
            //     @include mediaQuery(1280px){
            //         width: 596px;
            //         height: 596px;
            //         top: -125px;
            //     }
            //     @include mediaQuery(1140px){
            //         width: 496px;
            //         height: 576px;
            //     }
            //     @include mediaQuery($ipad){
            //         left: -300px;
            //     }
            //     @include mediaQuery(576px){
            //         left: -200px;
            //         top: -375px;
            //     }
            // }
            @include mediaQuery($ipad){
                width: 100%;
                // background-color: $yellowcircle;
                // padding: 30px 15px;
            }
            h1{
                position: relative;
                z-index: 2;
                // font-weight: 700;
                font-size: 65px;
                color: $white;
                line-height: 68.25px;
                font-family: $font_family_medium;
                font-weight: 500;
                letter-spacing: -0.01em;
                @include mediaQuery(1280px){
                    @include mediaFontStyle(65,70)
                }
                @include mediaQuery($ipad){
                    // max-width: 100%;
                    @include mediaFontStyle(40,44)
                }
            }
        }
        &_detail{
            margin-top: 12px;
            position: relative;
            z-index: 2;
            p{
                @include defaultFontStyleFpg($font_family_bold,16,21,$white);
            }
        }
    }
}