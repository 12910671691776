.tooltip_title{
    margin-bottom: 15px;
    button{
        margin-left: 30px;
    }
}
.team_info_section{
    padding-bottom: 10px;
    padding-top: 25px;
    &:last-child.border_bottom_gray{
        border: none;
    }
    &:last-child{
        margin-bottom: 60px;
    }
}
.step_common{
    .field_row_inner{
        label{
            // @include defaultFontStyle(400,14,18,$black,-0.34px);
            @include defaultFontStyleFpg($font_family_medium,14,18,$black,-0.34px);
            margin-bottom: 5px;
            display: inline-block;
            @include mediaQuery($ipad){
                @include mediaFontStyle(12,16)
            }
        }
        .tooltip-button{
            margin-left: 10px;
        }
        span{
        }
        .question_title{
            label{
                @include defaultFontStyle(400,20,23,$black,-0.48px);
                @include mediaQuery($ipad){
                    @include mediaFontStyle(16,18)
                }
            }
        }
    }
    .form_screening{
        padding-top: 40px;
        .form_screening_note{
            margin-top: 35px;
        }
        p{
            @include defaultFontStyle(700,18,20,$black,0.38px);
        }
        .question_title{
            @include mediaQuery($extraLarge){
                margin-bottom: 10px;
                max-width: 100%;
                margin-right: 0;
            }
        }
    }
    .form_footer_submit{
        .srv-validation-message{
            @include defaultFontStyleFpg($font_family_medium,14,26,$resetcolor,-0.29px);
            text-transform: uppercase;
        }
    }
}
// Step 8
.step_eight{
    .form_content{
        padding-bottom: 0;
        @include mediaQuery($ipad){
            padding-top: 30px;
        }
    }
    .form_content_questions{
        margin-bottom: 50px;
        border-bottom: 2px solid $black;
        @include mediaQuery($ipad){
            margin-bottom: 25px;
        }
        &:last-child{
            margin-bottom: 40px;
            @include mediaQuery($ipad){
                margin-bottom: 25px;
            }
        }
        .field_row_inner{
            .question_title{
                max-width: 653px;
                margin-right: 25px;
                width: 100%;

            }
           
        }
    }
}


// Step 9
.form_content{
    padding: 50px 0;
    &_top{
        &_title{
            padding-bottom: 60px;
            h3{
                margin-bottom: 15px;
            }
            h4{
                margin-bottom: 15px;
            }
            i{
                @include defaultFontStyle(400,20,23,$black,-0.48px);
                font-style: italic;
                display: block;
                margin-bottom: 10px;
            }
            p{
                @include defaultFontStyle(400,20,23,$black,-0.48px);  
                @include mediaQuery($ipad){
                    @include mediaFontStyle(16,18)
                }      
            }
        }
    }
    &_questions{
        .question{
            border-bottom: 1px solid $formborder;
            padding-bottom: 30px;
            margin-bottom: 30px;
            @include mediaQuery(767px){
                @include f_wrap;
            }
            &_label{
                max-width: 410px;
                width: 100%;
                margin-right: 40px;
                @include mediaQuery(767px){
                   max-width: 100%;
                   margin-right: 0;
                   margin-bottom: 10px;
                }
                p{
                    @include defaultFontStyle(400,20,23,$black,-0.48px);    
                    margin-bottom: 5px;    
                    @include mediaQuery($ipad){
                        @include mediaFontStyle(16,18)
                    }
                }
                span{
                    @include defaultFontStyle(400,14,14,$black,-0.29px);        
                }
            }
            &_dropdown{
                @include mediaQuery($ipad){
                    width: 100%;
                }
                &_inner{
                    margin-bottom: 30px;
                    @include mediaQuery($ipad){
                        width: 100%;
                    }
                    &_wrap,> div{
                        @include mediaQuery($ipad){
                            width: 100%;
                        }
                    }
                    &:last-child{
                        margin-bottom: 0;
                    }
                    .not_appliocable{
                        margin-left: 15px;
                        margin-top: 10px;
                        @include mediaQuery($large){
                            margin-top: 0;
                        }
                    }
                }
            }
            .action_wrap{
                margin:5px 0 0 15px;
                align-self: flex-start;
                span{
                    margin-right: 11px;
                    &:last-child{
                        margin-right: 0;
                    }
                }
            }
        }
        &_inner{
            max-width: 991px;
            width: 100%;
            padding-bottom: 55px;
            @include mediaQuery($ipad){
                padding-bottom: 30px;
            }
        }
    }
    .privacy_policy_link{
        border-top: 2px solid $black;
    }
   
}
.check_col_2{
    margin-top: 15px;
    .checkbox_field_row{
        max-width: 50%;
        width: 100%;
        @include mediaQuery($mobile){
            max-width: 100%;
        }
    }
}
.no_border{
    .question{
        border: none;
        margin: 0;
    }
}