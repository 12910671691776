.sd_film_desc_header{
    padding: 186px 0 65px;
    background-repeat: no-repeat;
    border-bottom: 2px solid #000;
    // background: #00b2c0 url('../images/shot_film.png') no-repeat 100%;
    background: #ffa894;
    // background-size: cover;
    // background-position: bottom;
    @include mediaQuery($large) {
        padding: 80px 0 65px;
        background-image: none ;
        // background: $lylightgreen;
        // background-position: 100% -400px;
        // background-size: cover;
    }
    @include mediaQuery(605px){
        padding-top: 66px;
    }
    &.sd_film_detail_pg{
        background: $white;
        border-bottom: 1px solid $black;
        .sd_film_desc_sec{
            display: flex;
            align-items: flex-start;
            @include mediaQuery($large){
                display: block;
            }
            @include mediaQuery($large) {
                margin: 0 -18px;
            }
            .sd_film_desc_img_slider{
                max-width: 714px;
                min-width: 714px;
                margin-right: 60px;
                @include mediaQuery($large) {
                    margin: 0 auto;
                    max-width: 790px;
                    min-width: 790px;
                }
                @include mediaQuery($ipad) {
                    max-width: 500px;
                    min-width: 500px;
                }
                @include mediaQuery($mobileLarge) {
                    min-width: unset;
                    padding: 0 15px;
                }
                .slick-slider{ 
                    @include mediaQuery($large) {
                        background: $lylightgreen url('../images/fpg_mobile1.png') no-repeat top;
                        background-size: 100% 100%;
                        padding: 80px;
                    }
                    @include mediaQuery($mobile) {
                        padding: 0;
                    }
                }
                .sd_film_desc_img{
                    max-height: 535px;
                    @include mediaQuery($mobile) {
                        padding: 70px 0 50px 0;
                        margin-bottom: 0;
                    }
                    
                    img{
                        max-height: 535px;      
                        @include square(100%);
                        // min-height: 535px;
                        object-position: center top;
                        object-fit: contain !important;
                        @include mediaQuery($mobile) {
                            min-height: 190px;
                            max-height: 203px;   
                        }
                    }
                }
            }
            
        }
    }
    .sd_film_desc_wpr {
        max-width: 520px;
        width: 100%;
        @include mediaQuery($large) {
            max-width: 100%;
              margin-top: 14px;
              padding: 0 15px;
          }
          @include mediaQuery($mobile) {
              margin-top: 14px;
              padding: 0 15px;
          }
        .sd_film_description{
            h2{
                @include defaultFontStyleFpg($font_family_MMbold,44,47,$black);
                margin-bottom: 24px;
                text-transform: inherit;
                @include mediaQuery($large){
                    @include mediaFontStyle(38,35);
                    letter-spacing: -0.32px;
                }
            }  
        }
        .sd_film_description_content {
            @include defaultFontStyleFpg($font_family_MMnormal,16,23,$black);
            font-weight: normal !important;
            padding: 8px 0 0 0;
            p{
                @include defaultFontStyleFpg($font_family_MMnormal,16,23,$black);
                font-weight: normal !important;
                strong{
                    @include defaultFontStyleFpg($font_family_MMnormal,16,23,$black);
                    font-weight: normal !important;
                }
            }
        }
    }
}
.sd_film_artists_section{
    @include border_bottom_black;
    .sd_inner_container{
        @include mediaQuery($ipad) {
            padding: 0;
        }
    }

.sd_film_artists_sec{
    @include flex;
    @include mediaQuery($ipad) {
        @include flex_dir_column;
    }
    .sd_film_artists_wpr{
        max-width: 740px;
        width: 100%;
        &:first-child{
            border-right: 1px solid $black;
            @include mediaQuery($ipad) {
                border-right: 0;
            }
        }
        .sd_film_artists_header{
            @include mediaQuery($ipad){
                padding: 0 ;
                margin: 31px 0 28px 0;
            }
            h2{
                @include defaultFontStyleFpg($font_family_MMbold, 25, 30, $black);  
                text-transform: none;
            }
        }
        .sd_film_artists_meet_sec{
            @include mediaQuery($ipad){
                max-width: 500px;
                width: 100%;
                margin: 0 auto;
                border-right: 0 !important;
            }
            .sd_panelist_block{
                .sd_panelist_img{
                    margin-right: 39px;
                    @include mediaQuery($ipad){
                        float: unset;
                        margin-right: 0;
                    }
                    .sd_panelist_img_inner{
                        width: 223px;
                        // height: 214px;
                        @include mediaQuery($ipad){
                            max-width: 377px;
                            width: 100%;
                            height: 100%;
                        }
                        img{
                            border-radius: 8px;
                        }
                    }
                }
                .sd_panelist_name{
                    @include mediaQuery($ipad){
                        min-height: unset;
                    }
                    h3{
                        @include defaultFontStyleFpg($font_family_MMbold, 25, 30, $black);  
                        padding-bottom: 9px;
                        @include mediaQuery($ipad){
                           align-items: center;
                        }
                    }
                }
                .sd_panelist_desc{
                    *{
                        @include defaultFontStyleFpg($font_family_MMnormal, 16, 23, $black);  
                        font-size: 16px !important;
                        line-height: 23px !important;
                    }
                }
            }
        }

        @include mediaQuery($ipad) {
            padding-right: 17px;
            padding-left: 18px;
        }
    }

}
// Header
.sd_film_artists_header{
    padding: 52px 0 27px;
    @include mediaQuery($mobile) {
        padding: 74px 0 26px;           
    }
    h2{
        @include defaultFontStyleFpg($font_family_normal,40,40,$black,-.96px);
       
        @include mediaQuery($ipad) {
        }
    }
}
// Meet Artist
.sd_film_artists_meet{
    padding: 0 93px 152px 0;
    @include mediaQuery($large) {
        padding: 0 40px 100px 0;
    }
    @include mediaQuery($ipad) {
        padding: 0 0 50px 0;
        @include border_bottom_black;
    }
    .sd_film_artists_meet_sec{
        .sd_panelist_block{
            max-width: 100%;
            margin-bottom: 65px;
            &:after{
                content: '';
                clear: both;
                display: table;
            }
            @include mediaQuery($mobile) {
                margin-bottom: 35px;     
            }
             .sd_panelist_img_inner{
                    width: 170px;
                margin-bottom: 28px;
                position: relative;
                @include mediaQuery($large) {
                    margin-bottom: 21px; 
                }
                @include mediaQuery(370px) {
                    margin-bottom: 10px;
                }
                img{
                    @include square(100%);
                }
            }
            .sd_panelist_img {
                margin-right: 28px;
                float: left;
            }
            .sd_panelist_name{
                @include flex_align_center;    
                padding: 0px 0 13px;
                @include mediaQuery($large) {
                    @include flex_justify_center;
                    @include flex_dir_column;
                    align-items: flex-start;
                }
                @include mediaQuery($ipad) {            
                    min-height: 123px;      
                    padding: 0;  
                }
                h3{
                    @include defaultFontStyleFpg($font_family_medium, 26, 25, $black); 
                    letter-spacing: -0.62px;  
                    @include mediaQuery($large) {
                        padding: 0 0 15px;  
                    }
                    @include mediaQuery($ipad) {
                        letter-spacing: -0.53px;      
                    }
                }
            }
            .sd_panelist_desc{
                margin-left: 198px;
                @include mediaQuery($large) {
                    margin-left: 0;
                    clear: both;
                }
              
                li{
                    margin-bottom: 5px !important;
                }
                p{
                    @include defaultFontStyleFpg($font_family_normal, 18, 25, $black);   
                    letter-spacing: -0.43px; 
                    @include mediaQuery($ipad) {
                        letter-spacing: -0.35px; 
                    }      
                }
            }
            .sd_panelist_social{
                @include flex_align_center;
                list-style: none;
                margin-left: 13px;
                @include mediaQuery($large) {
                    margin: 0 0 10px 0;
                }
                li{
                    @include flex_shrink;
                    margin-left: 18px;
                    @include mediaQuery($large) {
                        &:first-child{
                            margin-left: 0;
                        }
                    }
                    &.sd_twitter_preview{
                        img{
                            filter: invert(1);
                        }
                    }
                    img{
                        width: 16px;
                    }
                }
            }
        }
    }
    .sd_film_artists_video{
        padding-bottom: 50px;
        border-bottom: 2px solid $black;
        margin-bottom: 50px;
        &:last-child{
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom: 0;
        }
    }
    // Video
    .sd_video_frame_wpr{
        .sd_video_frame{
            position: relative;
            padding-bottom: 56.25%;
            height: 0;
            iframe{
                position: absolute;
                top: 0;
                left: 0;
                @include square(100%);
                border: none;
            }
        }
    }
    }

    // Credits
.sd_film_artists_credits{
    padding: 0 0 30px 84px;
    @include mediaQuery($large) {
        padding: 0 0 15px 40px;
    }
    @include mediaQuery($ipad) {
        padding-left: 0;
    }

    .sd_film_artists_header{
        @include mediaQuery($mobile) {
            padding: 42px 0 34px;           
        }
    }

    .sd_film_artists_credits_sec{
        max-width: 645px;
    }
    ul{
        margin-bottom: 35px;
        &:last-child{
            margin-bottom: 0;
        }
        @include mediaQuery($mobile) {
            margin-bottom: 26px;         
        }
    }
    li{
        @include flex;
        list-style: none;
        // &.sd_credit_link{
            // @include f_wrap;
        // }
        .sd_film_artists_cr_pos{
            @include defaultFontStyleFpg($font_family_MMmedium, 16, 30, $black);   
            letter-spacing: -0.43px;  
            display: block;
            max-width: 226px;
            width: 100%;
            margin-right: 23px;
            text-transform: uppercase;
            @include flex_shrink;
            @include mediaQuery($large) {
                max-width: 180px;
            }
            @include mediaQuery($mobile) {
                letter-spacing: -0.33px;
                max-width: 154px;
            }
        }
        .sd_film_artists_cr_name{
            p, a{                
                @include defaultFontStyleFpg($font_family_MMnormal, 16, 30, $black);   
                letter-spacing: -0.43px; 
                word-break: break-all;
                @include mediaQuery($mobile) {
                    letter-spacing: -0.33px;
                } 
            }
            .sd_credit_link_title{
                color: #1351dc;
                word-break: break-all;
            }
            // a{
            //     @include LimitLineText(1);
            //     max-width: 396px;
            //     width: 100%;
            //     word-break: break-word;
            // }
        }
    }
}
}


// 404 page 
// .notfound{
//     .sd_header_section{
//         // background: url('../../images/header_bg_purple.png') no-repeat;
//         background-size: 100% 100%;
//         padding: 178px 0 240px;
//         @include mediaQuery($desktop){
//             background-size: cover;
//         }
//         @include mediaQuery($extraLarge){
//             padding: 140px 0 150px;
//         }
//         @include mediaQuery($ipad){
//             padding: 150px 0 100px;
//         }
//     }
//     .sd_not_found_header{
//         h1{
//             @include defaultFontStyle(400, 90, 80, $black, -2.16px);
//             margin-bottom: 10px;
//             @include mediaQuery($extraLarge){
//                 font-size: 70px;
//             }
//             @include mediaQuery($ipad){
//             }
//         }
//         p{
//             @include defaultFontStyle(400, 40, 40, $black);
//             @include mediaQuery($ipad){
//             }
//             @include mediaQuery($mobile){
//             }
//         }
//     }
// }

// // Not found
// .nofound_content{
//     text-align: center;
//     padding: 40px 0;
//     a{
//         @include defaultFontStyle(500, 22, 32, $trinidad, -0.53px);
//     }
//     p{
//         @include defaultFontStyle(500, 22, 32, $black, -0.53px);
//         @include mediaQuery($ipad){
//             font-size: 20px;
//         }    
//     }
// }