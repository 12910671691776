
//  Signin pages
.sd_form_group{
    padding-bottom: 20px;
    position: relative;
    label{
        @include defaultFontStyleFpg($font_family_medium,12,20,$black,-0.24px);
        display: block;
        @include mediaQuery(991px){
        }
        img{
            display: inline-block;
            margin-left: 5px;
        }
    }
    .border_btn{
        position: relative;
        @include Borderbtn;
        .submit_loading{
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    a{
        @include defaultFontStyleFpg($font_family_medium,14,20,$black,-0.33px);
    }
}

// Text Field
.field_input{
    input{
        display: block;
        width: 100%;
        border: none;
        outline: none;
        height: 40px;
        @include defaultFontStyle(400, 14, 20, $black, -0.38px);
        border: 1px solid $black;
        padding:10px 17px;
        &::placeholder{
            color: $placeholder;
        }
        &:disabled{
            background:$lynxWhite2;
            color: $black;
        }
        &:read-only{
            background: $lynxWhite;
            // padding-left: 0;
            padding-bottom: 0;
            padding-top: 0;
            padding-right: 0;
            pointer-events: none;
        }
        @include mediaQuery(480px){
            padding:10px;
        }
    }
    &.passwordField{
        input{
            padding-right: 40px;
        }
    }
    textarea{
        height: 110px;
        width: 100%;
        border: 1px solid $black;
        resize: none;
        padding:10px 11px;
        @include defaultFontStyle(400, 16, 20, $black, -0.34px);
        &:read-only{
            color: $black;
            background: $lynxWhite;
        }
        @include mediaQuery(480px){
            padding:10px;
        }
    }
}

// Dropdown
.select_field{
    width: 312px;
    select{
        border: none;
        border-radius: 0;
        @include defaultFontStyle(400,14,16,$black);
        width: 100%;
        padding: 11px 40px 11px 10px;
        -webkit-appearance: none;
        outline: none;
        background:$white url('./../images/dropdown.svg') no-repeat;
        background-position: 96%;
        border: 1px solid $black;
        &:disabled{
            color: $black;
            cursor: auto;
            background: $lynxWhite ;
            background-position: 96%;
            opacity: 1;
            padding-bottom: 0;
            padding-top: 0;
            padding-right: 0;
            height: 40px;
        }
    }
 
    @include mediaQuery($mobile){
        width: 100%;
    }
    .css-b62m3t-container, .css-3iigni-container{
        .css-1s2u09g-control, .css-1pahdxg-control, .css-1insrsq-control{
            border-radius: 0;
            border: 1px solid black;
            min-height: 40px;
            color: black;
            font-family: 'ABCMonumentGrotesk_Regular' !important;
            font-style: normal;
            font-weight: 400;
            font-size: 1.4rem;
            box-shadow: none;
            .css-14el2xx-placeholder{
                color: black;
            }
        }
        .css-26l3qy-menu{
            font-family: 'ABCMonumentGrotesk_Regular' !important;
            font-style: normal;
            font-weight: 400;
            font-size: 1.4rem;
        }
    }
}
.addcredit_form{
    .select_field{
        width: 80%;
    }
}
.border_select{
    margin-top: 10px;
    &:last-child{
        padding-bottom: 0;
    }
    .select_field,.field_input{
        width: 248px;
        margin-left: auto;
        select{
            border-width: 0 0 1px 0;
        }
    }
}


// Checkbox
.checkbox_field_row{
    margin-bottom: 20px;
}
.checkBox{
    position: relative;
    input{
        position: absolute;
        opacity: 0;
        // width: 100%;
        left: 0;
        &:disabled{
            & ~ label{
                &::before{
                    background: $lynxWhite;
                }
            }    
        }
        &:checked{
            & ~ label{
                &:after{
                    opacity: 1;
                }
            }
            &:disabled{
                & ~ label{
                    &::before{
                        background: $lynxWhite;
                    }
                    &::after{
                        background: url("./../images/check_no.svg");
                        transform: rotate(0);
                        top: 0;
                        left: 0;
                        width: 20px;
                        height: 20px;
                        background-repeat: no-repeat;
                        background-size: contain;
                    }
                }
            }
        }
    }
    label{
        padding-left: 30px;
        position: relative;
        display: inline-block;
        color: $black;
        @include defaultFontStyle(400,20,23);
        @include mediaQuery($ipad){
            @include mediaFontStyle(12,16)
        }
        &::before{
            position: absolute;
            content: "";
            left: 0;
            width: 20px;
            height: 20px;
            border: 1px solid $black;
        }
        &::after{
            position: absolute;
            content: "";
            left: 7px;
            top: 7px;
            transform: rotate(-45deg) translateY(-50%);
            width: 11px;
            height: 5px;
            border: 1px solid $black; 
            border-top: transparent;
            border-right: transparent;
            opacity: 0;
        }
    }
    &.top_check{
        label{
            padding-left: 20px;
            position: relative;
            display: block;
            color: $black;
            @include defaultFontStyle(400,14,16);
            &::before{
                position: absolute;
                content: "";
                left: 0;
                width: 12px;
                height: 12px;
                border: 1px solid $black;
                top: 4px;
                transform: unset;
                
            }
            &::after{
                position: absolute;
                content: "";
                left: 3px;
                top: 7px;
                transform: rotate(-45deg);
                width: 6px;
                height: 3px;
                border: 1px solid $black; 
                border-top: transparent;
                border-right: transparent;
                opacity: 0;
            }
        }
    }
}

// Auth Pages 
.sd_auth_content{
    max-width: 654px;
    margin: 0 auto;
    padding: 33px 0 35px;
    .error_msg{
        @include defaultFontStyleFpg($font_family_medium,14,20,$resetcolor);
    }
    &_top{
        margin-bottom: 65px;
    }
    &_form_title{
        @include defaultFontStyleFpg($font_family_bold,40,40,$black);
        margin-bottom: 10px;
    }
    .border_btn{
        margin-right: 41px;
        &:last-child{
            margin-right: 0;
        }
    }
    .auth_button_wrap{
        margin-top: 20px;
    }
    .message_success{
        margin-top: 15px;
        p{
            @include defaultFontStyleFpg($font_family_medium,14,15,$resetcolor);
            max-width: 312px;
            margin: 0 auto;
        }
    }
}
.sd_information_title{
    @include defaultFontStyleFpg($font_family_bold,30,37.29,$black);
    margin: 36px 0 20px;
    p{
        @include defaultFontStyleFpg($font_family_normal,16,19.89,$black,0);
        margin-bottom: 10px;
        a{
            @include defaultFontStyleFpg($font_family_normal,16,19.89,#2438EF,0);
        }
    }
}
.post_acceptance_link{
    max-width: 264px;
    @include mediaQuery($mobileLarge){
        margin: 0 auto 25px;
    }
    a{
        padding: 10px;
        // border: 1px solid;
        background-color: $black;
        width: 100%;
        margin:5px 0 25px;
        border-radius: 8px;
        svg{max-width: 40px;max-height: 30px;}
        p{
            @include defaultFontStyleFpg($font_family_medium,15,16,$white,-0.32px);
            font-weight: 500;
            margin:0 10px;
            text-transform: uppercase;
        }
        .arrow_icon{
            margin-top: 10px;
            margin-left: 20px;
            display: none;
        }
    }
    p{
        @include defaultFontStyleFpg($font_family_normal,16,19.89,$black,0);
        &.sd_dates{
            display: none;
            @include defaultFontStyleFpg($font_family_bold,16,19.89,$black,0);
            font-weight: 700;
            margin-top: 20px;
        }
    }
}