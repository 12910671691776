.form_footer{
    @include flex_align_center();
    justify-content: space-between;
    .left_side{
        @include flex_align_center();
        .icon{
            margin-right: 14px;
            cursor: pointer;
        }
        .text{
            @include defaultFontStyleFpg($font_family_normal, 22,22, $black,-0.53);  
            text-decoration: underline;
            cursor: pointer;
            &:hover{
                color: $lygreen;
                text-decoration: none;
            }
        }
        @include mediaQuery($mobile){
            width: 100%;
            margin-bottom: 15px
        }
    }
    .right_side{
        @include flex_align_center();
        .btn{
            margin-right: 22px;
            button{
                cursor: pointer;
                transition: 0.2s ease-in-out;
                @include defaultFontStyle(400,22,22, $white,-0.53);
                padding: 21px 0 18px 0;
                width: 200px;
                background-color: $black;
                border: 2px solid $black;
                border-radius: 50px;
                .back{
                    margin-right: 15px;
                }
                .next{
                    margin-left: 15px;
                }
                svg{
                    filter: brightness(0) invert(1);
                }
                &:hover{
                    color: $black;
                    background-color: $white;
                    svg{
                        filter: brightness(1) invert(0);
                    }
                }
                @include mediaQuery($ipad){
                    width: 135px;
                }
            }
            &:last-child{
                margin: 0;
            }
        }
        @include mediaQuery($mobile){
            width: 100%;
        }
    }
    @include mediaQuery($mobile){
        flex-wrap: wrap;
        justify-content: center;
    }
}