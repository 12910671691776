.inner_title{
    p{
        @include defaultFontStyle(400,40,45, $black,-0.96);
        margin-bottom: 30px;
        @include mediaQuery($ipad){
            @include defaultFontStyle(400,30,40, $black,-0.96);
        }
        @include mediaQuery($mobile){
            @include defaultFontStyle(400,25,30, $black,-0.96);
        }
    }
}
.dest{
    @include defaultFontStyleFpg($font_family_medium,20,26, $black);
    min-width: 226px;
    width: 226px;
    @include mediaQuery($ipad){
        @include defaultFontStyleFpg($font_family_medium,16,24, $black);
    }
    @include mediaQuery($mobile){
        @include defaultFontStyleFpg($font_family_medium,14,22, $black);
        min-width: 150px;
        width: 150px;
    }
}
.details{
    p{
        @include defaultFontStyle(400,20,26, $black);
        min-width: 226px;
        width: 500px;
        @include mediaQuery($ipad){
            @include defaultFontStyle(400,16,24, $black);
        }
        @include mediaQuery($mobile){
            @include defaultFontStyle(400,14,22, $black);
            min-width: 150px;
            width: 150px;
        }
    }   
}
.container{
    max-width: 1280px;
    margin: 0 auto;
    width: 100%;
}
.step2{
    .credits{
        // padding-top: 55px;
        padding-bottom: 35px;
        max-width: 990px;
        text-transform: capitalize;
        .credit{
            border-bottom: 1px solid $gainsboro;
            padding: 10px 0;
            &:first-child{
                padding-top: 0
            }
        }
        @include mediaQuery($ipad){
            padding-bottom: 25px;
        }
    }
    .additional{
        padding-bottom: 50px;
        max-width: 991px;
        text-transform: capitalize;
        .des{
            @include flex_center_between();
            .add_des{
                @include defaultFontStyle(400,14,17.75, $black);
                max-width: 743px;
            }
            .name_count{
                @include defaultFontStyle(400,24,30, $lygreen);
            }
            @include mediaQuery($large){
                flex-wrap: wrap;
            }
        }
        .for_table{
            max-width: 100%;
            overflow: auto;
            table{
                width: 100%;
                margin-top: 29px;
                border-collapse: collapse;
                border-spacing: 0;
                tr{
                    vertical-align: baseline;
                    td{
                        padding: 16px 0;
                        border-bottom: 1px solid $gainsboro;
                        &:first-child{
                            @include defaultFontStyle(400,14,14, $lygreen,-0.34);
                            padding-right: 20px;
                            text-decoration: underline;
                            cursor: pointer;
                            max-width: 50px;
                        }
                        &:nth-child(2){
                            min-width: 226px;
                            // @include defaultFontStyle(400,20,26, $black);
                        }
                        &:nth-child(3){
                            min-width: 220px;
                            // @include defaultFontStyle(400,20,26, $black);
                        }
                        &:nth-child(4){
                            min-width: auto;
                            .close_icon{
                                @include flex();
                                justify-content: flex-end;
                                margin-right: 30px;
                                @include defaultFontStyle(400,13,26, $black);
                                svg{
                                    cursor: pointer;
                                }
                            }
                            vertical-align: middle;
                        }
                        &:last-child{
                            vertical-align: middle;
                            min-width: 40px;
                            img{
                                margin: 0 auto
                            }
                        }
                    }
                }
                @include mediaQuery($ipad){
                    margin-top: 15px;
                }
            }
        }
        .add_credit{
            @include flex_align_center;
            margin-top: 19px;
            .icon{
                margin-right: 18px;
                cursor: pointer;
            }
            .text{
                @include defaultFontStyle(400,18,26, $lygreen);
            }
        }
        @include mediaQuery($ipad){
            padding-bottom: 25px;
        }
    }
    .principal{
        max-width: 990px;
        padding-bottom: 70px;
        text-transform: capitalize;
        .des{
            @include flex_center_between();
            .add_des{
                @include defaultFontStyle(400,14,17.75, $black);
                max-width: 743px;
            }
        }
        .credit{
            margin-top: 26px;
            border-top: 1px solid $gainsboro;
            border-bottom: 1px solid $gainsboro;
            padding: 25px 0;
            &:first-child{
                padding-top: 0
            }
        }
        @include mediaQuery($ipad){
            padding-bottom: 25px;
        }
    }
    .footer{
        margin-bottom: 55px;
    }
}

.underline{
    border-bottom: 2px solid $black;
    margin-bottom: 55px;
    &.change_margin{
        margin-bottom: 40px;
    }
    @include mediaQuery($ipad){
        margin-bottom: 25px;
        &.change_margin{
            margin-bottom: 25px;
        }
    }
}

.addcredit_form{
    .fullname_div{
        .field_row_inner{
            @include mediaQuery(575px){
                width: 100%;
                margin-bottom: 10px;
            }
        }
        @include mediaQuery(575px){
            flex-wrap: wrap;
        }
    }
    .select_field{
        select{
            // border: none !important;
            // // width: 250px !important;
            // padding: 0 !important;
            // font-family: $font_family_medium !important;
            // font-weight: 400;
            // font-size: 22px !important;
            // line-height: 23px !important;
            color: #000000 !important;
            letter-spacing: -0.53 !important;
            margin-bottom: 10px;
            
            font-family: $font_family_medium !important;
            padding-left: 15px;
            padding-right: 30px;
            font-size: 22px !important;
            line-height: 22px !important;
            -webkit-appereance: none;
            // height: 45px ;
            background: $white url('./../images/dropdown.svg') no-repeat;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            background-position: 99%;
            background-size: 25px;
            @include mediaQuery($ipad){
                // width: 230px !important;
                font-size: 18px !important;
                background-size: 20px;
                line-height: 19px !important;
            }
            @include mediaQuery($mobile){
                // width: 200px !important;
                font-size: 16px !important;
                line-height: 17px !important;
            }
        }
        @include mediaQuery($ipad){
            width: 230px !important;
        }
        @include mediaQuery($mobile){
            width: 200px !important;
        }
    }
    .addcredit{
        width: 100%;
        margin-bottom: 15px;
        @include flex_align_center();
        span{
            margin-top: 30px;
            margin-left: 20px;
            margin-right: 0;
            @include mediaQuery($ipad){
                margin-left: 10px;
                margin-top: 0;
            }
            &:last-child{
                // margin-left: 20px;
                @include mediaQuery($mobile){
                    // margin-left: 20px;
                }
            }
        }
    }
}