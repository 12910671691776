.sd_container_990{max-width: 990px;width: 100%}
.project_info_form{
    @include border_bottom_black;
    padding-bottom: 47px;
    @include mediaQuery($ipad){
        padding-bottom: 35px;
    }
    @include mediaQuery($mobile){
        padding-bottom: 25px;
    }
    .project_info_form_inner{
        .project_info_form_inner_top{
            .sd_flex{
                @include mediaQuery($ipad){
                    @include f_wrap;
                }
            }
            .field_row_inner{
                width: calc(33.33% - 18px);
                @include mediaQuery($ipad){
                    width: calc(50% - 10px);
                }
                @include mediaQuery($mobile){
                    width: 100%;
                }
                .select_field{
                    width: auto;
                }
            }
            padding-bottom: 10px;
            .top_2_field{
                @include mediaQuery($ipad){
                    @include justify_between;
                }
                & > div{
                    width: calc(33.33% - 18px);
                    margin-right: 30px;
                    @include mediaQuery($ipad){
                        width: calc(50% - 10px);
                        margin-right: 0;
                    }
                }
            }
        }
        .project_info_form_inner_bottom{
            margin-top: 30px;
            .sd_flex{
                @include mediaQuery($mobile){
                    flex-wrap: wrap;
                }
            }
            .sd_page3_heading{
                margin-bottom: 30px;
                p{
                    @include defaultFontStyleFpg($font_family_bold,20,23,$black);
                    margin-bottom: 10px;
                }
            }
            .left_content{
                // max-width: 515px;
                margin-right: 15px;
                margin-bottom: 30px;
                width: 52%;
                @include mediaQuery($mobile){
                    width: 100%;
                    margin-bottom: 15px;
                }
                p{
                    @include defaultFontStyle(400,20,23,$black);
                    letter-spacing: -0.48px;
                    @include mediaQuery($ipad){
                        @include mediaFontStyle(16,20);
                    }
                    @include mediaQuery($mobile){
                        @include mediaFontStyle(14,18);
                    }
                }
                span{
                    @include defaultFontStyle(400,12,14,$black);
                    margin-top: 5px;
                    display: block;
                }
            }
            .right_content{
                width: 48%;
                @include mediaQuery($mobile){
                    width: 100%;
                }
                .select_field{
                    @include mediaQuery($ipad){
                        width: auto;
                    }
                }
            }
            .ai_question{
                & > p{
                    @include defaultFontStyleFpg($font_family_normal,20,23,$black);
                }
            }
        }
    }
}

.digital_media_form{
    @include border_bottom_black;
    padding-bottom: 47px;
    margin-bottom: 40px;
    @include mediaQuery($ipad){
        margin-bottom: 35px;
        padding-bottom: 35px;
    }
    @include mediaQuery($mobile){
        margin-bottom: 25px;
        padding-bottom: 25px;
    }
    .form_inner_title{
        h3{
            padding-bottom: 13px;
            @include mediaQuery($ipad){
                flex-wrap: wrap;
            }
            span{
                @include defaultFontStyle(400,12,14,$black);
                margin-left: 35px;
                @include mediaQuery($ipad){
                    margin-left: 0;
                    margin-top: 5px;
                    width: 100%;
                }
            }
        }
        p{
            @include defaultFontStyle(400,14,18,$black);
            margin-bottom: 25px !important;
        }
    }
    .digital_media_form_inner{
        .field_row_inner{
            max-width: 460px;
            .sd_input_prefix{
                @include defaultFontStyle(400,14,26,$black);
                display: block;
                padding-left: 15px;
                text-transform: initial;
                height: 38px;
                line-height: 38px;
                background-color: $gainsboro;
                width: 110px;
            }
            .sd_field_prefix_row{
                display: flex;
                align-items: center;
                border: 1px solid $black;
                padding: 0;
                height: 40px;
                input{
                    border: none;
                    height: 38px;
                    padding-left: 0;
                }
            }
        }
    }
}